<template>
  <LoginForm :Heading = "getHeading()" @submit="submit"/>
</template>

<script>
import LoginForm from './Form.vue'
import { mapState, mapActions } from 'vuex'
import { initializePlatformMatomo, DataForMatomoTracking, adminLogData } from '../../utils/matomoTracking'
import { apiResponseType, apiResponseValue } from '../../utils/dataValidations'
import { ApiClient } from '../store/api'
const HUBIMEIValidation = require("./../../utils/HUBValidations/HUBIMEIValidation")
const formatIMEIdata = require("./../../utils/formatIMEIdata")
const csv = require("papaparse")

export default {
  components: { LoginForm },
  computed: {
    ...mapState([
      'isProd',
      'platform'
    ])
  },
  mounted: function () {
    initializePlatformMatomo()
  },
  beforeUpdate: function () {
    if (this.platform !== this.$route.query.platform) {
      this.clearMessage()
    }
  },
  methods: {
    ...mapActions([
      'loading',
      'setErrorMessage',
      'clearMessage',
      'success'
    ]),
    getHeading: function (){
      return this.$route.query.platform + ' IMEI Addition'
    },
    async submit (file, filename, username, password) {
      this.loading(true)
      this.setErrorMessage({ isError: false, errMsg: '' })
      this.success({ isOk: false, succMsg: '' })
      let apiResponse = apiResponseType.FAILED
      let data = null
      const parsedCSV = csv.parse(file, {
        complete: async function (results){
          const validationError = HUBIMEIValidation(results.data)
          if (validationError){
            this.setErrorMessage({ isError: true, errMsg: validationError })
            this.loading(false)
          } else {
            data = formatIMEIdata(results.data)
            let jsonUploadResponse
            try {
              jsonUploadResponse = await ApiClient.post(
                username,
                password,
                JSON.stringify(data),
                '/v1/merm/imei', 'registryENV'
              )
            } catch (error) {
              this.setErrorMessage({ isError: true, errMsg: apiResponseType.JSONUploadFailed })
            }
            this.loading(false)
            if (jsonUploadResponse){
              if (jsonUploadResponse.data.success === true){
                this.setErrorMessage({ isError: false, errMsg: '' })
                apiResponse = apiResponseType.SUCCESS
                this.success({ isOk: true, succMsg: 'IMEIs are added.' })
              } else if (jsonUploadResponse.data.error != null){
                this.setErrorMessage({ isError: true, errMsg: 'An exception has occurred.' })
              } else if (Array.isArray(jsonUploadResponse.data.data)) {
                var arrayobj = jsonUploadResponse.data.data

                var outputlist = arrayobj.map(function (item) {
                  return "[" + item.deploymentCode + " : " + item.imei + "]" + "\n"
                })

                apiResponse = apiResponseType.PARTIALSUCCESS
                this.setErrorMessage({ isError: true, errMsg: outputlist + ' following imeis were not added , please check the DeploymentCode and IMEI in DB' })
              } else {
                this.setErrorMessage({ isError: true, errMsg: 'Something went wrong' })
              }

              adminLogData(this.getHeading(), 'StatusCode: ' + jsonUploadResponse.status, 'Filename: ' + filename)

              if (apiResponse === apiResponseType.PARTIALSUCCESS){
                const SuccessList = data.filter(inputData => !jsonUploadResponse.data.data.some(responseArray => (inputData.DeploymentCode === responseArray.deploymentCode && inputData.IMEI === responseArray.imei)))
                DataForMatomoTracking(this.getHeading(), apiResponseType.SUCCESS, SuccessList)
                DataForMatomoTracking(this.getHeading(), apiResponseType.FAILED, jsonUploadResponse.data.data)
              } else {
                DataForMatomoTracking(this.getHeading(), apiResponse, data)
              }
            }
          }
        }.bind(this)
      })
    }
  }
}
</script>
