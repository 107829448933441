function formatData (csvFileAsArrays) {
  const fileRows = [...csvFileAsArrays]
  const listToSend = []
  const inputImei = fileRows.slice(1).map(row => {
    return {
      deploymentCode: row[0],
      imei: row[1]
    }
  })
  return { inputImei }
}
module.exports = formatData
